import React from 'react'

import '../../components/title/title.css';
import './footer.css';

const Footer = () => {
    return (
        <div className='pcp__footer'>
            <div className="pcp__footer-inner">
                <div className="pcp__footer-name">
                    <h1>PC Picker</h1>
                </div>
                <div className="pcp__footer-copyright">
                    <h3>©2023 <br />All Rights Reserved</h3>
                </div>
                <div className='pcp__footer-links'>
                    <div className="pcp__footer-link-container">
                        <h3>Links</h3>
                        <ul>
                            <a href=""><li>Our Picks</li></a>
                            <a href=""><li>Contact Us</li></a>
                            <a href=""><li>About Us</li></a>
                        </ul>
                    </div>
                    <div className="pcp__footer-link-container">
                        <h3>Legal</h3>
                        <ul>
                            <a href=""><li>Privacy Policy</li></a>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer