import React from 'react'

import './feature.css'
import '../title/title.css'

const Feature = (props: { title: string, passage: string }) => {
    return (
        <div>
            <h1>{props.title}</h1>
            <p className='pcp__feature-passage'>{props.passage}</p>
        </div>
    )
}

export default Feature