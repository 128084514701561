import React from 'react'

import { Navbar, Header, Feature } from '../../components';
import { Footer, Today } from '../../containers';

import './landing.css'

const Landing = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <div className='pcp__landing-padding'>
                <div className="pcp__landing-split">
                    <Feature title="Our Goal" passage="Here at PC Picker we aim to get you the best deal on computers by watching for any and all deals that come up. We’ve designed a platform built for any budget. Do you need something more powerful for video editing? We have you covered. Do you need a computer purely for emails? No matter the case we've got you covered." />
                    <Feature title="Spec Sheets?" passage="Because our time is precious we can’t all worry about what sort of usage we are going to get from a laptop, so let us take that worry from your plate. Now you will have no need to look at a spec sheet for a laptop again. We'll make sure the laptop is perfect for you and fits your descriptions." />
                </div>
            </div>
            <Today />
            <div className="pcp__landing-padding">
                <Feature title="Amazon Affiliates" passage="We don't charge you anything, how great is that? We are Amazon Affiliates, this means that we get paid based on your purchase, this is not a surcharge added to your order, just that we get a cut of Amazon’s profit." />
            </div>
            <Footer />
        </div>
    )
}

export default Landing