import React from 'react'

import './navbar.css'

const Navbar = () => {
    return (
        <div>
            <div className="pcp__navbar">
                <div className="pcp__navbar-inner">
                    <div className="pcp__navbar-logo">
                        <h3>PC Picker</h3>
                    </div>
                    <div className="pcp__navbar-links">
                        <a href="">Home</a>
                        <a href="">About Us</a>
                        <a href="">Our Picks</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar