import React from 'react'

import './header.css'

import computer_image from '../../assets/computer.avif';

const Header = () => {
    return (
        <div className='landing__header'>
            <img src={computer_image} alt="Generic Computer" className="landing__header-img" />
        </div>
    )
}

export default Header