import React from 'react';

import './computer.css'
import '../button/button.css'

import { computer_type } from '../../utils/types'

const Computer = (props: { computer: computer_type }) => {
    return (
        <div className='pcp__card'>
            <div className='pcp__card-image'>
                <img src="https://images.unsplash.com/photo-1593642531955-b62e17bdaa9c" alt={`Showing ` + props.computer.name} />
            </div>
            <div className='pcp__card-content'>
                <div className='pcp__card-content-info'>
                    <div className='pcp__card-content-text'>
                        <h4 className='pcp__card-content-name'>{props.computer.name}</h4>
                        <p className='pcp__card-content-stock'>{props.computer.stock ? "In Stock" : "Out of Stock"}</p>
                    </div>
                    <div className='pcp__card-content-price'>
                        <h6><span>$</span>{props.computer.price}</h6>
                    </div>
                </div>
                <div className='pcp__card-content-cta'>
                    <a href="www.google.com" target="_blank" className="button button-primary button-block">Read More</a>
                </div>
            </div>

        </div>
    )
}

export default Computer