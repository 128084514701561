import React from 'react'

import { Computer } from '../../components'

import './today.css';
import '../../components/title/title.css'

import { computer_type } from '../../utils/types'

const computers: computer_type[] = [
    {
        name: "Dell Optiplex",
        price: 129,
        stock: false
    },
    {
        name: "Dell Optiplex",
        price: 129,
        stock: false
    },
    {
        name: "Dell Optiplex",
        price: 129,
        stock: false
    }
]

const Today = () => {
    return (
        <div className='pcp__today'>
            <h1 className='pcp__today-title'>Today's Picks</h1>
            <div className='pcp__today-cards'>
                {
                    computers.map((comp) => {
                        return <Computer key={comp.name} computer={comp} />
                    })
                }
            </div>
        </div>
    )
}

export default Today